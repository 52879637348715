export default class NavController {
  constructor() {
    const $q = document.querySelector.bind(document);
    const $qa = document.querySelectorAll.bind(document);

    this.$navigation = $q('.navigation');
    this.$navLink = $qa('.nav-link');
    this.$closeTrigger = $qa('.navigation-open-bg, .nav-close');
    this.$menuTrigger = $q('.nav-trigger');
    this.activeClass = 'active';
    this.navIsHidden = 'nav-hidden';
    this.$navOverlay = $q('.navigation-open-bg');
  }

  executeNav() {
    const that = this;

    if (!this.$navigation.classList.contains(this.navIsHidden)) {
      this.$navOverlay.classList.add(this.activeClass);
    } else {
      this.$navOverlay.classList.remove(this.activeClass);
    }


    /**
     * check if nav level has sub on mobile
     */
    const navLinks = this.$navLink;
    function checkForSubOnClickOnMobile() {
      if (!window.matchMedia('(min-width: 1024px)').matches) {
        for (let i = 0; i < navLinks.length; i += 1) {
          navLinks[i].addEventListener('click', (e) => {
            if (e.target.parentNode.classList.contains('has-sub') && e.target.nextElementSibling.tagName === 'UL') {
              e.target.nextElementSibling.classList.add(that.activeClass);
              e.preventDefault();
              return false;
            }
            return true;
          }, false);
        }
      }
    }

    checkForSubOnClickOnMobile();
    window.addEventListener('resize', () => {
      checkForSubOnClickOnMobile();
    });


    /**
     * set nav headline for sub-levels
     */
    const subLevelUl = document.querySelectorAll('.sitemap-level:not(.level-01)');
    for (let i = 0; i < subLevelUl.length; i += 1) {
      let parentNavLinkContent = subLevelUl[i].parentNode.querySelector('.nav-link').innerHTML;
      subLevelUl[i].innerHTML = '<span class="nav-headline">' + parentNavLinkContent + '</span>' + subLevelUl[i].innerHTML;
    }


    /**
     * open correct level
     */
    const levelItems = document.querySelectorAll('.level-item');
    for (let i = 0; i < levelItems.length; i += 1) {
      if (levelItems[i].querySelector('.sitemap-level') !== null) {
        levelItems[i].classList.add('has-sub');
      }
      if (levelItems[i].classList.contains('active') && levelItems[i].parentNode !== null) {
        levelItems[i].parentNode.classList.add('active');
      }
    }


    /**
     * go back to previous navigation level
     */
    const navBackLinks = document.querySelectorAll('.nav-back');
    for (let j = 0; j < navBackLinks.length; j += 1) {
      navBackLinks[j].addEventListener('click', (e) => {
        e.target.parentNode.classList.remove(this.activeClass);
      }, false);
    }


    /**
     * open mobile navigation
     */
    this.$menuTrigger.addEventListener('click', (e) => {
      e.stopPropagation();
      this.$navigation.classList.toggle(this.navIsHidden);
      this.$navOverlay.classList.toggle(this.activeClass);
      this.$menuTrigger.classList.toggle(this.activeClass);
    });


    /**
     * close mobile navigation
     */
    const navCloseTrigger = this.$closeTrigger;
    for (let i = 0; i < navCloseTrigger.length; i += 1) {
      navCloseTrigger[i].addEventListener('click', () => {
        this.$navigation.classList.add(this.navIsHidden);
        this.$navOverlay.classList.remove(this.activeClass);
        this.$menuTrigger.classList.remove(this.activeClass);
      }, false);
    }
  }
}
